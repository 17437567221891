@import '~@adludio/components/dist/Style/colourPalette';	

body {
  background: $g200;
}

@media (min-width: 600px) {
  // On desktop, show the light dotted pattern;
  body {
    background: white;
    background-image: radial-gradient(#e2e2e2 1.8px, transparent 0);
    background-size: 2rem 2rem;
  }
}
